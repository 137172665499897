import { createTheme } from '@material-ui/core/styles';
import { LabelDisplayedRowsArgs } from '@material-ui/core';
import styles from './styles.module.scss';

const theme = createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTablePagination: {
      labelRowsPerPage: 'Ridu ühel lehel:',
      labelDisplayedRows: (paginationInfo: LabelDisplayedRowsArgs) =>
        `Read ${paginationInfo.from}-${paginationInfo.to}, kokku ${paginationInfo.count}`,
    },
  },
  palette: {
    primary: {
      main: styles.primaryBlue,
    },
  },
  overrides: {
    MuiTypography: {
      subtitle1: {
        fontWeight: 600,
      },
      subtitle2: {
        fontWeight: 600,
      },
    },
    // @ts-ignore
    MuiGridPanelFooter: {
      root: {
        display: 'none',
      },
    },
    // @ts-ignore
    MuiDataGrid: {
      root: {
        padding: '32px 26px',
        border: 0,

        '& .MuiDataGrid-cell': {
          '& .MuiInputBase-input, & .MuiTypography-root': {
            fontSize: 14,
          },
          '&:first-child': {
            padding: 0,
          },
        },
        '& .MuiDataGrid-columnHeader': {
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 600,
              fontSize: '15px',
            },
            '&:first-child': {
              padding: 0,
            },
          },
          '&:first-child': {
            padding: 0,
          },
        },
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: styles.white,
        '& .MuiDataGridColumnsPanel-container': {
          '& .MuiDataGridColumnsPanel-column': {
            '&:last-child': {
              //turn off last element in selectable columns panel
              display: 'none',
            },
          },
        },
      },
      elevation1: {
        boxShadow: '0px 0px 10px #00000029',
        backdropFilter: 'unset',
        backgroundColor: styles.white,
      },
    },
    MuiAutocomplete: {
      root: {
        '& .MuiInputBase-root': {
          fontWeight: 400,
        },
      },
    },
    MuiRadio: {
      root: {
        padding: 2,
      },
    },

    MuiAccordionSummary: {
      content: {
        '& .MuiTypography-root': {
          fontWeight: 600,
        },
      },
      root: {
        padding: 0,
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 0,
      },
    },
    MuiAccordion: {
      root: {
        '& .MuiDataGrid-root': {
          padding: '0',
        },
        boxShadow: 'none',
        borderBottom: `1px solid ${styles.disabledGrey}`,
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        backgroundColor: styles.white,
      },
    },
  },
});

export default theme;
