import { InstitutionTypes } from 'redux/slices/typesSlice';
import { baseRequest } from 'utils/baseRequest';

const findAndDownloadSearchReports = async (
    formData: FormData
): Promise<{ data: any, status: any } | null> => {
  try {
    const { data, status } = await baseRequest({
      method: 'POST',
      endpoint: 'api/data-report/search-and-download?rmkFields=true&tramFields=true&excelFormat=true',
      data: formData,
      responseType: 'blob',
    });
    if (data) {
      return { data, status };
    }
  } catch (e) {
    console.error(e);
    const errorResponse = (e as any)?.response;
    if (errorResponse) {
      return { data: null, status: errorResponse.status };
    }
  }
  return null;
};

const fetchInstitutionTypes = async (type: string): Promise<InstitutionTypes[]> => {
  try {
    const {
      data: { status, message },
    } = await baseRequest({
      method: 'GET',
      endpoint: `api/institutions/${type}`,
    });
    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
  return [];
};

export { fetchInstitutionTypes, findAndDownloadSearchReports };
