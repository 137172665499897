import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Procedure } from '../../utils/procedures/types';

const initialState: Procedure[] = [];

export const proceduresSlice = createSlice({
    name: 'procedures',
    initialState,
    reducers: {
        setProcedures: (state, action: PayloadAction<Procedure[]>) => action.payload,
        removeProcedure: (state, action: PayloadAction<Procedure>) => 
            state.filter((row: Procedure) => row.id !== action.payload.id),
    }
});

export const {setProcedures, removeProcedure} = proceduresSlice.actions;
export default proceduresSlice.reducer;