import { createAction } from '@reduxjs/toolkit';

export const setEditInstitutionMode = createAction('@setEditInstitutionMode', (item) => {
  return { payload: item };
});
export const setInstitutions = createAction('@setInstitutions', (payload) => ({
  payload,
}));
export const setAddNewInstitution = createAction('@setAddNewInstitution', (payload) => ({
  payload,
}));
export const modifyInstitution = createAction('@modifyInstitution', (payload) => ({
  payload,
}));
export const setActiveInstitutionRow = createAction('@setActiveInstitutionRow', (payload) => ({
  payload,
}));
export const reset = createAction('@reset', (item) => {
  return { payload: item };
});
