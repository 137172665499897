import React from 'react';
import useStyles from './UserMenu.styles';
import { List, ListItem, ListItemText } from '@material-ui/core';

interface Props {
  onLogOut: () => void;
}

const UserMenu = ({ onLogOut }: Props) => {
  const classes = useStyles();

  return (
    <List className={classes.menuContainer}>
      <ListItem button onClick={onLogOut}>
        <ListItemText secondary='Logi välja' />
      </ListItem>
    </List>
  );
};

export default UserMenu;
