import { createSlice } from '@reduxjs/toolkit';
import {
  setVariableList,
  setActiveRowData,
  setTemplates,
  saveNewTemplate,
  setDeleteTemplate,
  modifyTemplate,
  setDocumentData,
} from 'redux/actions/templatesActions';
import { GridRowData } from '@material-ui/data-grid';
import { Variable, Document } from 'utils/templatesData/types';
import { Case } from './typesSlice';

interface DocumentData {
  contractDocumentTypes: Case[];
  fieldTypes: Case[];
  procedureDocumentTypes: Case[];
}

interface TemplatesState {
  variableList: Variable[];
  documents: Document[];
  activeRowData: GridRowData;
  documentData: DocumentData;
}

const initialState: TemplatesState = {
  variableList: [],
  documents: [],
  documentData: {} as DocumentData,
  activeRowData: {},
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: {
    [setVariableList.toString()]: (state, { payload }) => {
      state.variableList = payload;
    },
    [setTemplates.toString()]: (state, { payload }) => {
      state.documents = payload;
    },
    [setActiveRowData.toString()]: (state, { payload }) => {
      state.activeRowData = payload;
    },
    [setDocumentData.toString()]: (state, { payload }) => {
      state.documentData = payload;
    },
    [saveNewTemplate.toString()]: (state, { payload }) => {
      const documents = state.documents;
      const index = documents.findIndex((document) => document.title === payload.title);
      if (index === -1) {
        state.documents.push(payload);
      }
    },
    [modifyTemplate.toString()]: (state, { payload }) => {
      const documents = state.documents;
      const index = documents.findIndex((document) => document.id === payload.id);
      state.documents[index] = {
        ...state.documents[index],
        title: payload.title,
        caseType: payload.caseType,
        caseSubType: payload.caseSubType,
        content: payload.content,
      };
    },
    [setDeleteTemplate.toString()]: (state, { payload }) => {
      const documents = state.documents;
      const index = documents.findIndex((document) => document.id === payload);
      state.documents.splice(index, 1);
    },
  },
});

export default templatesSlice.reducer;
