import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GridRowId } from '@material-ui/data-grid';
import { Observation } from '../../utils/observations/types';

interface UpdateObservationsPayload {
  selectedObservations: GridRowId[];
  properties: Record<string, unknown>;
}

interface ObservationsState {
  items: Observation[];
  search: Record<string, unknown>;
}

const initialState: ObservationsState = {
  items: [],
  search: {},
};

export const observationsSlice = createSlice({
  name: 'observations',
  initialState,
  reducers: {
    setObservations: (state, action: PayloadAction<Observation[]>) => {
      state.items = action.payload;
    },
    addObservations: (state, action: PayloadAction<Observation[]>) => {
      state.items = [...action.payload, ...state.items];
    },
    removeObservation: (state, action: PayloadAction<Observation>) => {
      state.items = state.items.filter((row: Observation) => row.id !== action.payload.id);
    },
    updateObservations: (state, action: PayloadAction<UpdateObservationsPayload>) => {
      const { selectedObservations, properties } = action.payload;
      state.items = state.items.map((observation: Observation) => {
        if (selectedObservations.includes(observation.id)) {
          return {
            ...observation,
            ...properties,
          };
        }
        return observation;
      });
    },
    setSearch: (state, action: PayloadAction<Record<string, unknown>>) => {
      state.search = action.payload;
    },
  },
});

export const {
  setObservations,
  addObservations,
  removeObservation,
  updateObservations,
  setSearch,
} = observationsSlice.actions;

export default observationsSlice.reducer;
