import { AxiosResponse } from 'axios';
import { baseRequest } from 'utils/baseRequest';

const getTargetUrl = async (redirectUrl: string): Promise<AxiosResponse | undefined> => {
  try {
    const { data } = await baseRequest({
      method: 'post',
      endpoint: 'get-target-url',
    });
    if (data.message) {
      const url = data.message;
      return url.replace('redirect_uri=', `redirect_uri=${redirectUrl}`);
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

const getAuthCode = async (
  code: string,
  redirectUrl: string
): Promise<AxiosResponse | undefined> => {
  try {
    const { data } = await baseRequest({
      method: 'post',
      endpoint: 'exchange-auth-code',
      data: {
        code,
        redirectUrl,
      },
    });
    if (data.jwt) {
      return data.jwt;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};
const getUserInfo = async (): Promise<AxiosResponse | undefined> => {
  try {
    const { data } = await baseRequest({
      method: 'get',
      endpoint: 'grant-user-info',
    });
    if (data.message.identificationCode) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

const getPing = async (): Promise<number | undefined> => {
  try {
    const { status, data } = await baseRequest({
      method: 'get',
      endpoint: 'api/ping',
    });
    if (status === 200) {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
};

const refreshSession = async (): Promise<number | undefined> => {
  try {
    const { status } = await baseRequest({
      method: 'get',
      endpoint: 'refresh-session',
    });
    if (status === 200) {
      return status;
    }
  } catch (error) {
    console.error(error);
  }
};

const logOut = async (redirectUrl: string): Promise<AxiosResponse | undefined> => {
  try {
    const { data } = await baseRequest({
      method: 'get',
      endpoint: 'log-out',
    });
    if (data) {
      return data.replace('post_logout_redirect_uri=', `post_logout_redirect_uri=${redirectUrl}`);
    }
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export { getTargetUrl, getAuthCode, logOut, getUserInfo, getPing, refreshSession };
