import { createAction } from '@reduxjs/toolkit';
import { Report } from 'utils/reports/types';

export const setReports = createAction('@setReports', (item) => {
  return { payload: item };
});
export const addReport = createAction('@addReport', (item) => {
  return { payload: item };
});
export const modifyReport = createAction('@modifyReport', (item) => {
  return { payload: item };
});
export const setSelectedReports = createAction('@setSelectedReports', (reports, group?) => {
  if (group?.id) {
    const modifiedReports = reports.map((report: Report) => ({
      ...report,
      aruandeGrupiNr: group.groupName,
      appraisalReportGroupId: group.id,
    }));
    return { payload: modifiedReports };
  }
  return { payload: reports };
});
export const setGroups = createAction('@setGroups', (item) => {
  return { payload: item };
});
export const addGroup = createAction('@addGroup', (item) => {
  return { payload: item };
});
export const deleteReport = createAction('@deleteReport', (item) => {
  return { payload: item };
});
