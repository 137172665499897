import { baseRequest } from './baseRequest';

export interface GenericTypeWithSubtypes {
  name: string;
  description: string;
  subtypes: GenericType[];
}

export interface GenericType {
  name: string;
  description: string;

  [key: string]: any;
}

const fetchProcedureTypes = async (): Promise<GenericTypeWithSubtypes[]> => {
  try {
    const {
      data: { status, message },
    } = await baseRequest({ method: 'GET', endpoint: 'api/types/procedure-types/' });
    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
  return [];
};

const fetchTypes = async (type: string): Promise<GenericType[] | GenericTypeWithSubtypes[]> => {
  try {
    const {
      data: { status, message },
    } = await baseRequest({
      method: 'GET',
      endpoint: `api/types/${type}`,
    });
    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
  return [];
};

export { fetchProcedureTypes, fetchTypes };
