import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import { HeaderNavigation, MenuItem, Role } from 'katri-map';
import { Button, Grid, Typography, CircularProgress } from '@material-ui/core';
import { useAppSelector } from '../../../redux/reduxHooks';
import {
  userHasAnyRole,
  userHasAnyRoleExcept,
  userHasRole, userIsOnlyPublicUser,
} from '../../../utils/userData/user-utils';
import UserMenu from '../UserMenu/UserMenu';
import {
  Business as BusinessIcon,
  DescriptionOutlined,
  ExpandMore as ExpandMoreIcon,
  MapOutlined,
  VisibilityOutlined,
  AccountCircleOutlined,
  CloudDownloadOutlined,
  Assignment as AssignmentIcon,
  HelpOutline,
  Help,
  FindInPage,
} from '@material-ui/icons';
import { ReactComponent as ReportsIcon } from '../../../assets/paste_24px_outlined.svg';
import useStyles from './HeaderNavigationContainer.styles';

interface HeaderNavigationContainerProps {
  onLogin: () => void;
  onLogOut: () => void;
}

const HeaderNavigationContainer = ({ onLogin, onLogOut }: HeaderNavigationContainerProps) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState<boolean>(false);
  const [userMenuOpen, setUserMenuOpen] = React.useState<boolean>(false);
  const { identificationCode, firstName, lastName, roles } = useAppSelector(
    (state) => state.authentication.activeUser
  );

  const permissionsMenuItems: MenuItem[] =
    userHasRole(roles, Role.REGISTRY_ADMINISTRATOR) || userHasRole(roles, Role.INSTITUTION_ADMIN)
      ? [
          {
            label: 'Kasutajaõigused',
            key: 'user-permissions',
            path: '/user-permissions',
            icon: <AccountCircleOutlined style={{ color: 'white' }} />,
          },
          {
            label: 'Asutusteõigused',
            key: 'institution-permissions',
            path: '/institution-permissions',
            icon: <BusinessIcon style={{ color: 'white' }} />,
          },
        ]
      : [];

  const anyRolesMenuItems: MenuItem[] = userHasAnyRole(roles) && !userIsOnlyPublicUser(roles)
    ? [
        {
          label: 'Kasutusjuhend spetsialistile',
          key: 'specialistInstructions',
          url: 'https://maaamet.ee/media/1744/download',
          icon: <Help style={{ color: 'white' }} />,
        },
        {
          label: 'Välisvaatlused',
          key: 'observations',
          path: '/observations',
          icon: <VisibilityOutlined style={{ color: 'white' }} />,
        },
        {
          label: 'Hindamisaruanded',
          key: 'reports',
          path: '/reports',
          icon: <ReportsIcon />,
        },
        {
          label: 'Andmeväljavõtted',
          key: 'data-reports',
          path: '/data-reports',
          icon: <CloudDownloadOutlined style={{ color: 'white' }} />,
        },
        {
          label: 'Lepingute detailotsing',
          key: 'contracts',
          path: '/contracts',
          icon: <FindInPage style={{ color: 'white' }} />,
        },
      ]
    : [];

  const documentsMenuItem: MenuItem[] = userHasAnyRoleExcept(roles, Role.OBSERVER) && !userIsOnlyPublicUser(roles)
    ? [
        {
          label: 'Dokumentide koostamine',
          key: 'documents',
          path: '/documents',
          icon: <DescriptionOutlined style={{ color: 'white' }} />,
        },
        {
          label: 'Menetlused',
          key: 'procedures',
          path: '/procedures',
          icon: <AssignmentIcon style={{ color: 'white' }} />,
        },
      ]
    : [];

  const menuItems: MenuItem[] = [
    {
      label: 'Kaart',
      key: 'map',
      path: '/',
      icon: <MapOutlined style={{ color: 'white' }} />,
    },
    {
      label: 'Kasutusjuhend',
      key: 'instructions',
      url: 'https://maaamet.ee/media/1741/download',
      icon: <HelpOutline style={{ color: 'white' }} />,
    },
    ...anyRolesMenuItems,
    ...documentsMenuItem,
    ...permissionsMenuItems,
  ];

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Suspense fallback={<CircularProgress />}>
      <HeaderNavigation
        open={open}
        onToggle={toggleDrawer}
        menuItems={menuItems}
        reactRouterLinkComponent={Link}
      >
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={6}>
            <Typography variant='h4' component='h1' noWrap color='primary'>
              <Link className={classes.homeLink} to='/'>
                KATRI
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            {identificationCode ? (
              <div className={classes.userInfoContainer}>
                <Button
                  color='primary'
                  className={classes.user}
                  onClick={() => setUserMenuOpen(!userMenuOpen)}
                >
                  {`${firstName} ${lastName}`}
                  <ExpandMoreIcon />
                </Button>
                {userMenuOpen && (
                  <UserMenu
                    onLogOut={() => {
                      setUserMenuOpen(false);
                      onLogOut();
                    }}
                  />
                )}
              </div>
            ) : (
              <>
                <Button onClick={onLogin} color='primary'>
                  Logi sisse
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </HeaderNavigation>
    </Suspense>
  );
};

export default HeaderNavigationContainer;
