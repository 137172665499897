import { createAction } from '@reduxjs/toolkit';
export const addNewUser = createAction('@addNewUser', (payload) => ({
  payload,
}));
export const modifyRole = createAction('@modifyRole', (payload) => ({
  payload,
}));
export const modifyEmail = createAction('@modifyEmail', (payload) => ({
  payload,
}));
export const addNewInstitution = createAction('@addNewInstitution', (payload) => ({
  payload,
}));
export const setEditUserMode = createAction('@setEditUserMode', (payload) => ({
  payload,
}));
export const setUserList = createAction('@setUserList', (payload) => ({
  payload,
}));
export const setRoleList = createAction('@setRoleList', (payload) => ({
  payload,
}));
export const deleteRole = createAction('@deleteRole', (payload) => ({
  payload,
}));
export const setSelectedUser = createAction('@setSelectedUser', (payload) => ({
  payload,
}));
export const setIsAddRoleOpen = createAction('@setIsAddRoleOpen', (payload) => ({
  payload,
}));
export const setCurrentlyOpenedInstitution = createAction(
  '@setCurrentlyOpenedInstitution',
  (payload) => ({
    payload,
  })
);

export const clearInputFields = createAction('@clearInputFields', (payload) => ({ payload }));
export const reset = createAction('@reset', (payload) => ({ payload }));
