import axios from 'axios';
import { baseRequest } from '../baseRequest';
import { Observation, ObservationSearchData } from './types';
import { ActiveUser } from '../authentication/types';

const getCadastreAddress = async (
  cadastreId: string
): Promise<{ [key: string]: string | [] } | undefined> => {
  try {
    const {
      data: { addresses },
      status,
    } = await axios({
      method: 'GET',
      url: `https://inaadress.maaamet.ee/inaadress/gazetteer?address=${cadastreId}`,
    });
    if (status === 200) {
      return addresses.length ? addresses[0] : undefined;
    }
  } catch (e) {
    console.error(e);
  }
};

const fetchObservations = async (): Promise<Observation[]> => {
  try {
    const {
      data: { status, message },
    } = await baseRequest({
      method: 'GET',
      endpoint: 'api/observations/v2',
    });
    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
  return [];
};

const filterObservations = async (body: ObservationSearchData): Promise<Observation[]> => {
  try {
    const {
      data: { status, message },
    } = await baseRequest({
      method: 'POST',
      endpoint: 'api/observations/filter',
      data: body as unknown as Record<string, unknown>,
    });
    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
  return [];
};

const searchObservations = async (searchString: string): Promise<Observation[]> => {
  try {
    const {
      data: { status, message },
    } = await baseRequest({
      method: 'GET',
      endpoint: `api/observations/search/${searchString}`,
    });
    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
  return [];
};

const createObservation = async (
  body: Record<string, unknown>
): Promise<Observation | undefined> => {
  try {
    const {
      data: { status, message },
    } = await baseRequest({
      method: 'POST',
      endpoint: 'api/observations',
      data: body,
    });
    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
};

const updateObservation = async (
  observationId: number,
  body: Record<string, unknown>
): Promise<Observation | undefined> => {
  try {
    const {
      data: { status, message },
    } = await baseRequest({
      method: 'PUT',
      endpoint: 'api/observations',
      data: body,
    });
    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
};

const deleteObservation = async (observationId: number): Promise<Observation | undefined> => {
  try {
    const {
      data: { status, message },
    } = await baseRequest({
      method: 'DELETE',
      endpoint: `api/observations/${observationId}`,
    });
    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
};

const getPerformingUsers = async (cadastreId: string): Promise<ActiveUser[]> => {
  try {
    const {
      data: { status, message },
    } = await baseRequest({
      method: 'GET',
      endpoint: `/api/observations/performing-users/${cadastreId}`,
    });
    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
  return [];
};

const fetchRelatedUsers = async (): Promise<ActiveUser[]> => {
  try {
    const {
      data: { status, message },
    } = await baseRequest({
      method: 'GET',
      endpoint: 'api/observations/related-users',
    });
    if (status === 'OK') {
      return message;
    }
  } catch (e) {
    console.error(e);
  }
  return [];
};

export {
  getCadastreAddress,
  fetchObservations,
  filterObservations,
  searchObservations,
  createObservation,
  updateObservation,
  deleteObservation,
  getPerformingUsers,
  fetchRelatedUsers,
};
