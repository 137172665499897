import { createSlice } from '@reduxjs/toolkit';
import {
  setEditInstitutionMode,
  setInstitutions,
  setActiveInstitutionRow,
  setAddNewInstitution,
  modifyInstitution,
  reset,
} from '../actions/institutionPermissionsActions';
import { InstitutionPermission } from 'utils/userData/types';

interface CreateInstitutionPermissionsState {
  editInstitutionMode: boolean;
  activeInstitutionRow: InstitutionPermission;
  institutions: InstitutionPermission[];
}

const initialState: CreateInstitutionPermissionsState = {
  editInstitutionMode: false,
  activeInstitutionRow: <InstitutionPermission>{},
  institutions: [],
};

export const institutionPermissionsSlice = createSlice({
  name: 'institutionPermissions',
  initialState,
  reducers: {},
  extraReducers: {
    [setEditInstitutionMode.toString()]: (state, { payload }) => {
      state.editInstitutionMode = payload;
    },
    [setInstitutions.toString()]: (state, { payload }) => {
      state.institutions = payload;
    },
    [setActiveInstitutionRow.toString()]: (state, { payload }) => {
      state.activeInstitutionRow = payload;
    },
    [setAddNewInstitution.toString()]: (state, { payload }) => {
      state.institutions.push(payload);
    },
    [modifyInstitution.toString()]: (state, { payload }) => {
      const index = state.institutions.findIndex(({ id }) => id === payload.id);
      state.institutions[index] = payload;
    },
    [reset.toString()]: () => {
      () => initialState;
    },
  },
});

export default institutionPermissionsSlice.reducer;
