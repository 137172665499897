import axios, { Method, ResponseType, AxiosResponse } from 'axios';
import { KATRI_BE_URL } from '../configs/url-config';

export const BASE_URL = KATRI_BE_URL;

interface RequestPayload {
  method: Method;
  endpoint: string;
  data?: Record<string, unknown> | FormData | number[];
  baseUrl?: string;
  responseType?: ResponseType;
  contentType?: string;
}

export const baseRequest = async ({
  method,
  endpoint,
  data,
  baseUrl,
  responseType,
  contentType,
}: RequestPayload): Promise<AxiosResponse> => {
  return axios({
    method,
    baseURL: baseUrl || BASE_URL,
    url: endpoint,
    headers: {
      Accept: '*/*',
      'Content-Type': contentType || 'application/json',
    },
    responseType: responseType || 'json',
    withCredentials: true,
    ...(data && { data }),
  });
};
