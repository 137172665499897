import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  menuContainer: {
    position: 'absolute',
    background: 'white',
    width: '100%',
  },
}));

export default useStyles;
