import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  user: {
    textTransform: 'capitalize',
  },
  userInfoContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  homeLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

export default useStyles;
