interface GitlabWindow extends Window {
  KATRI_URL?: string;
  KATRI_BE_URL?: string;
  CONTACT_EMAIL?: string;
  CONTACT_PHONE?: string;
}

declare let window: GitlabWindow;

export const KATRI_URL = window.KATRI_URL || 'https://katridev.kataster.ee/';
export const KATRI_BE_URL = window.KATRI_BE_URL || 'https://katribedev.kataster.ee/';
