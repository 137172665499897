import { Role } from 'katri-map';
import { Permission } from './types';

export const userHasRole = (permissions: Permission<string | null>[], role: Role): boolean =>
  Boolean(
    permissions?.length &&
      permissions.find((permission: Permission<string | null>) => permission.roleEnum === role)
  );

export const userHasAnyRole = (permissions: Permission<string | null>[]): boolean =>
  Boolean(permissions?.length > 0);

export const userHasAnyRoleExcept = (permissions: Permission<string | null>[], role: Role): boolean => {
  if (!permissions?.length) return false;

  for (const permission of permissions) {
    if (permission.roleEnum !== role) {
      return true;
    }
  }
  return false;
};

export const userIsOnlyPublicUser = (permissions: Permission<string | null>[]): boolean => {
  if (!permissions.length || permissions.length > 1) return false;
  return permissions[0].roleEnum === Role.PUBLIC_USER;
};
