import React, { Suspense } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { et } from 'date-fns/locale';
import { MapProvider } from 'katri-map';
import { persistor, store } from 'redux/store';
import MainContainer from './components/MainContainer/MainContainer';
import '@fontsource/roboto';
import useSharedStyles from './components/shared.styles';
import 'react-toastify/dist/ReactToastify.css';
import './styles.module.scss';
import './App.css';

const App = () => {
  const sharedClasses = useSharedStyles();

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MapProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={et}>
            <Router>
              <Suspense
                fallback={
                  <div className={sharedClasses.suspenseSpinnerStyles}>
                    <CircularProgress/>
                  </div>
                }
              >
                <MainContainer/>
              </Suspense>
            </Router>
          </MuiPickersUtilsProvider>
        </MapProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
