import { createSlice } from '@reduxjs/toolkit';
import {
  setReports,
  addReport,
  modifyReport,
  setSelectedReports,
  deleteReport,
  setGroups,
  addGroup,
} from 'redux/actions/reportsActions';
import { Report, Group } from 'utils/reports/types';

interface ReportsState {
  reports: Report[];
  selectedReports: Report[];
  groups: Group[];
}

const initialState: ReportsState = {
  reports: [],
  selectedReports: [],
  groups: [],
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: {
    [setReports.toString()]: (state, { payload }) => {
      state.reports = payload;
    },
    [addReport.toString()]: (state, { payload }) => {
      state.reports.unshift(payload);
    },
    [modifyReport.toString()]: (state, { payload }) => {
      const index = state.reports.findIndex(({ id }) => id === payload.id);
      if (index || index === 0) {
        state.reports.splice(index, 1, payload);
      }
    },
    [setSelectedReports.toString()]: (state, { payload }) => {
      payload.forEach((report: Report) => {
        const index = state.reports.findIndex(({ id }) => id === report.id);
        if (index || index === 0) {
          state.reports.splice(index, 1, report);
        }
      });
      state.selectedReports = payload;
    },
    [setGroups.toString()]: (state, { payload }) => {
      state.groups = payload;
    },
    [deleteReport.toString()]: (state, { payload }) => {
      const index = state.reports.findIndex(({ id }) => id === payload);
      if (index || index === 0) {
        state.reports.splice(index, 1);
      }
    },
    [addGroup.toString()]: (state, { payload }) => {
      state.groups.push(payload);
    },
  },
});
export default reportsSlice.reducer;
