import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Role } from 'katri-map';
import {
  setEditUserMode,
  clearInputFields,
  addNewUser,
  addNewInstitution,
  setUserList,
  setRoleList,
  setSelectedUser,
  setCurrentlyOpenedInstitution,
  modifyRole,
  modifyEmail,
  setIsAddRoleOpen,
  reset,
} from '../actions/userPermissionsActions';
import {
  CreateUserPermissionsState,
  NewUserFieldTypes,
  InstitutionFieldTypes,
  User,
  Permission,
  InstitutionPermissions,
} from 'utils/userData/types';

const emptyPermission = {
  permission: '',
  beginDate: '',
  endDate: '',
  adderName: '',
};

const initialState: CreateUserPermissionsState = {
  editUserMode: false,
  selectedUser: <User>{},
  currentlyOpenedInstitution: '',
  newRowActiveType: '',
  isAddRoleOpen: false,
  institutionTypes: <InstitutionFieldTypes>{},
  newUser: <NewUserFieldTypes>{},
  userList: [],
  roleList: [],
  roleListEnums: {},
};

export const userPermissionsSlice = createSlice({
  name: 'userPermissions',
  initialState,
  reducers: {},
  extraReducers: {
    [setEditUserMode.toString()]: (state, { payload }) => {
      state.editUserMode = payload;
    },
    [clearInputFields.toString()]: (state, { payload }) => {
      const valueKey: string = payload.key;
      state.institutionTypes[valueKey] = emptyPermission;
    },
    [setUserList.toString()]: (state, { payload }) => {
      state.userList = payload;
    },
    [setSelectedUser.toString()]: (state, { payload }) => {
      state.selectedUser = payload;
    },
    [addNewUser.toString()]: (state, { payload }) => {
      state.userList.push(payload);
    },
    [modifyRole.toString()]: (state, { payload }) => {
      const { responseRole, selectedUser, currentlyOpenedInstitutionIndex, type } = payload;
      const selectedUserIndex = state.userList.findIndex(
        (user) => user.identificationCode === selectedUser.identificationCode
      );

      if (currentlyOpenedInstitutionIndex === -1) {
        // general permissions
        const clonedGeneralRoles = _.cloneDeep(selectedUser.generalRoles);
        if (type === 'add') {
          clonedGeneralRoles.push(responseRole);
        }
        if (type === 'update') {
          const activeRoleIndex = clonedGeneralRoles.findIndex(
            (role: Permission<string>) => role.id === responseRole.id
          );
          clonedGeneralRoles[activeRoleIndex] = responseRole;
        }
        state.userList[selectedUserIndex].generalRoles = clonedGeneralRoles;
        state.selectedUser.generalRoles = clonedGeneralRoles;
        return;
      }

      // institution permissions
      const clonedInstitutionRoles = _.cloneDeep(selectedUser.institutionRoles);
      if (type === 'add') {
        clonedInstitutionRoles[currentlyOpenedInstitutionIndex].roles.push(responseRole);
      }
      if (type === 'update') {
        const activeRoleIndex = clonedInstitutionRoles[
          currentlyOpenedInstitutionIndex
        ].roles.findIndex((role: Permission<string>) => role.id === responseRole.id);

        clonedInstitutionRoles[currentlyOpenedInstitutionIndex].roles[activeRoleIndex] =
          responseRole;
      }
      state.userList[selectedUserIndex].institutionRoles = clonedInstitutionRoles;
      state.selectedUser.institutionRoles = clonedInstitutionRoles;
    },
    [addNewInstitution.toString()]: (state, { payload }) => {
      const { responseRole, selectedUser } = payload;
      const selectedUserIndex = state.userList.findIndex(
        (user) => user.identificationCode === selectedUser.identificationCode
      );
      const clonedInstitutionList: InstitutionPermissions<string>[] = _.cloneDeep(
        state.userList[selectedUserIndex].institutionRoles
      );
      clonedInstitutionList.push({
        institutionName: responseRole.institutionName,
        roles: [responseRole],
      });
      state.userList[selectedUserIndex].institutionRoles = clonedInstitutionList;
      state.selectedUser.institutionRoles = clonedInstitutionList;
    },
    [modifyEmail.toString()]: (state, { payload }) => {
      const selectedUserIndex = state.userList.findIndex((user) => user.id === payload.id);
      state.userList[selectedUserIndex].email = payload.email;
    },
    [setRoleList.toString()]: (state, { payload }) => {
      state.roleListEnums = payload;
      Object.keys(payload).forEach((key: string) => {
        state.roleList.push({
          key: key as Role,
          label: payload[key],
        });
      });
    },
    [setCurrentlyOpenedInstitution.toString()]: (state, { payload }) => {
      state.currentlyOpenedInstitution = payload;
    },
    [setIsAddRoleOpen.toString()]: (state, { payload }) => {
      state.isAddRoleOpen = payload;
    },
    [reset.toString()]: () => {
      () => initialState;
    },
  },
});

export default userPermissionsSlice.reducer;
