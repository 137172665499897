import { createAction } from '@reduxjs/toolkit';
export const setProcedureTypes = createAction('@setProcedureTypes', (payload) => ({
  payload,
}));
export const setProcedureMethod = createAction('@setProcedureMethod', (payload) => ({
  payload,
}));
export const setUsageTypes = createAction('@setUsageTypes', (payload) => ({
  payload,
}));
export const setAppraisalTypes = createAction('@setAppraisalTypes', (payload) => ({
  payload,
}));
export const setSubdivisionTypes = createAction('@setSubdivisionTypes', (payload) => ({
  payload,
}));

export const setAuthorizedInstitutions = createAction('@setAuthorizedInstitutions', (payload) => ({
  payload
}));

export const setAdministratingInstitutions = createAction('@setAdministratingInstitutions', (payload) => ({
  payload
}));

export const setRelatedUsers = createAction('@setRelatedUsers', (payload) => ({
  payload
}));
