import React, {lazy, useEffect, useState} from 'react';
import axios from 'axios';
import { useAppSelector } from 'redux/reduxHooks';
import { useDispatch, batch } from 'react-redux';
import {Route, useHistory, useLocation, Redirect, Switch, matchPath, match} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { ErrorNotification } from 'katri-map';
import { setUserData, clearReduxState } from 'redux/actions/authenticationActions';
import HeaderNavigationContainer from '../header/HeaderNavigation/HeaderNavigationContainer';
import {
  getTargetUrl,
  getAuthCode,
  getUserInfo,
  getPing,
  logOut,
  refreshSession,
} from 'utils/authentication/authentication';
import { setRoleList } from 'redux/actions/userPermissionsActions';
import {
  setProcedureTypes,
  setProcedureMethod,
  setUsageTypes,
  setAppraisalTypes,
  setSubdivisionTypes,
  setAuthorizedInstitutions,
  setAdministratingInstitutions,
  setRelatedUsers,
} from 'redux/actions/typesActions';
import { getUserData } from 'utils/userData/userDataRequests';
import { fetchTypes } from 'utils/types-requests';
import { fetchInstitutionTypes } from 'utils/dataReports/dataReports-requests';
import { fetchRelatedUsers } from 'utils/observations/observations-requests';
import { KATRI_URL } from '../../configs/url-config';
import useStyles from './MainContainer.styles';
import {userIsOnlyPublicUser} from '../../utils/userData/user-utils';

const MapWrapper = lazy(() => import('./MapWrapper'));
const InstitutionPermissionsContainer = lazy(() => import('../permissions/InstitutionPermissionsContainer/InstitutionPermissionsContainer'));
const UserPermissionsContainer = lazy(() => import('../permissions/UserPermissionsContainer/UserPermissionsContainer'));
const ObservationsContainer = lazy(() => import('../observations/ObservationsContainer/ObservationsContainer'));
const ReportsContainer = lazy(() => import('../reports/ReportsContainer/ReportsContainer'));
const DataReports = lazy(() => import('components/DataReports/DataReports'));
const ReportsDetailedView = lazy(() => import('../reports/ReportsDetailedView/ReportsDetailedView'));
const GroupDetailedView = lazy(() => import('components/reports/GroupDetailedView/GroupDetailedView'));
const DocumentsContainer = lazy(() => import('../documents/DocumentsContainer/DocumentsContainer'));
const ExtendSessionModal = lazy(() => import('components/ExtendSessionModal/ExtendSessionModal'));
const ProceduresContainer = lazy(() => import('components/procedures/ProceduresContainer/ProceduresContainer'));

const redirectUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000/' : KATRI_URL;

const MainContainer = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isExtendSessionModalOpen, setIsExtendSessionModalOpen] = useState(false);
  const [secondsUntilSessionEnd, setSecondsUntilSessionEnd] = useState(0);
  const activeUser = useAppSelector((state) => state.authentication.activeUser);

  useEffect(() => {
    if (secondsUntilSessionEnd > 0) {
      const timeout = setTimeout(() => {
        setSecondsUntilSessionEnd(secondsUntilSessionEnd - 1);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
    if (isExtendSessionModalOpen && secondsUntilSessionEnd < 1) {
      setIsExtendSessionModalOpen(false);
      handleLogOut();
    }
  }, [secondsUntilSessionEnd, isExtendSessionModalOpen]);

  useEffect(() => {
    if (activeUser.identificationCode) {
      batch(async () => {
        if (!userIsOnlyPublicUser(activeUser.roles)) {
          dispatch(setProcedureTypes(await fetchTypes('procedure-types')));
          dispatch(setAppraisalTypes(await fetchTypes('appraisal-forms')));
          dispatch(setProcedureMethod(await fetchTypes('procedure-method')));
          dispatch(setUsageTypes(await fetchTypes('usage')));
          dispatch(setSubdivisionTypes(await fetchTypes('subdivisions')));
          dispatch(setAuthorizedInstitutions(await fetchInstitutionTypes('authorized')));
          dispatch(setAdministratingInstitutions(await fetchInstitutionTypes('administrating')));
          dispatch(setRelatedUsers(await fetchRelatedUsers()));
        }
      });
    }
  }, [activeUser.identificationCode]);

  useEffect(() => {
    if (activeUser.identificationCode) {
      getAndSetCurrentUserData();
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(history?.location.search);
    const code = params.get('code');
    if (code) {
      (async () => {
        const jwtToken = await getAuthCode(code, redirectUrl);
        if (jwtToken && typeof jwtToken === 'string') {
          getAndSetCurrentUserData();
          dispatch(setRoleList(await getUserData('users/roles/enums')));
          history.push(localStorage.getItem('originUrl') || '/');
          localStorage.removeItem('originUrl');
        }
      })();
    }
  }, [history]);

  useEffect(() => {
    history.listen(async () => {
      if (activeUser.identificationCode) {
        getAndSetCurrentUserData();
      }
    });
  }, [history, activeUser.identificationCode]);

  useEffect(() => {
    if (activeUser.identificationCode) {
      setInterval(async () => {
        const tokenRemainingSeconds = await getPing();
        if (tokenRemainingSeconds && tokenRemainingSeconds < 300) {
          setSecondsUntilSessionEnd(tokenRemainingSeconds);
          setIsExtendSessionModalOpen(true);
        }
      }, 120000);
    }
  }, [activeUser.identificationCode]);

  const handleLogin = async () => {
    localStorage.setItem('originUrl', location.pathname);
    const url = await getTargetUrl(redirectUrl);
    if (typeof url === 'string') {
      window.location.href = url;
    }
  };

  const handleLogOut = async () => {
    const url = await logOut(redirectUrl);
    if (typeof url === 'string') {
      setSecondsUntilSessionEnd(0);
      setIsExtendSessionModalOpen(false);
      window.location.href = url;
    }
    dispatch(clearReduxState());
  };

  const getAndSetCurrentUserData = async () => {
    const authenticatedCredentials = await getUserInfo();
    if (authenticatedCredentials) {
      dispatch(setUserData(authenticatedCredentials));
    }
  };

  const handleExtendSession = async () => {
    const successfulExtension = await refreshSession();
    if (successfulExtension) {
      setIsExtendSessionModalOpen(false);
      setSecondsUntilSessionEnd(0);
      toast.success('Sessioon pikendatud');
    } else {
      toast.success('Pikendamine ebaõnnestus');
      handleLogOut();
    }
  };

  const getPlkMatch = (): match<{ id: string }> | null =>
    matchPath(location.pathname, {
      path: '/plk-interest/:id',
      exact: true,
      strict: false,
    });

  return (
    <div className={classes.pageContainer}>
      <ErrorNotification
        axiosInstance={axios}
        onSessionEnded={handleLogOut}
        toastInstance={toast}
        ToastContainer={ToastContainer}
      />

      <HeaderNavigationContainer onLogin={handleLogin} onLogOut={handleLogOut} />
      <main className={classes.contentContainer}>
        <div className={classes.divider} />
        <div className={classes.content}>
          <ExtendSessionModal
            isOpen={isExtendSessionModalOpen}
            onClose={(event, reason) => {
              if (reason === 'backdropClick') {
                return;
              }
              setIsExtendSessionModalOpen(false);
            }}
            secondsUntilSessionEnd={secondsUntilSessionEnd}
            onLogout={handleLogOut}
            onExtendSession={handleExtendSession}
          />
          <Switch>
            <Route
              exact
              path={[
                '/',
                '/observation/:id',
                '/observations-plan',
                '/geometry/:id',
                '/edit-geometry/:id',
                '/cadastre/:id',
                '/necessity/:id',
                '/contracts',
                '/plk-interest/:id',
                '/plk-interest-view'
              ]}
              render={() => <MapWrapper userData={activeUser} />}
            />
            {activeUser.identificationCode && !userIsOnlyPublicUser(activeUser.roles) ? (
              <>
                <Route exact path='/user-permissions' render={() => <UserPermissionsContainer />} />
                <Route exact path='/observations' render={() => <ObservationsContainer />} />
                <Route
                  path='/reports'
                  render={() => {
                    const getMatchPath = (
                      path: string
                    ): { params: { id: string }; url: string } | null => {
                      return matchPath(history.location.pathname, {
                        path,
                        exact: true,
                      });
                    };

                    const detailedReport = getMatchPath('/reports/:id');
                    const detailedGroup = getMatchPath('/reports/group/:id');

                    if (
                      detailedReport?.params?.id &&
                      detailedReport?.url !== '/reports/detailed-search'
                    ) {
                      return <ReportsDetailedView id={detailedReport.params.id} />;
                    }

                    if (detailedGroup?.params?.id) {
                      return <GroupDetailedView id={detailedGroup.params.id} />;
                    } else {
                      return <ReportsContainer />;
                    }
                  }}
                />
                <Route exact path='/borderings' render={() => <div />} />
                <Route
                  exact
                  path='/institution-permissions'
                  render={() => <InstitutionPermissionsContainer />}
                />
                <Route exact path='/documents' render={() => <DocumentsContainer />} />
                <Route exact path='/data-reports' render={() => <DataReports />} />
                <Route exact path='/procedures' render={() => <ProceduresContainer />} />
              </>
            ) : (
              <Redirect to={getPlkMatch()?.url || '/'} />
            )}
          </Switch>
        </div>
      </main>
    </div>
  );
};


export default MainContainer;
