import { createSlice } from '@reduxjs/toolkit';
import {
  setProcedureTypes,
  setProcedureMethod,
  setUsageTypes,
  setAppraisalTypes,
  setSubdivisionTypes,
  setAuthorizedInstitutions,
  setAdministratingInstitutions,
  setRelatedUsers,
} from 'redux/actions/typesActions';
import { ActiveUser } from 'utils/authentication/types';

export interface Case {
  description: string;
  name: string;
}

export interface ProcedureTypes {
  description: string;
  name: string;
  subtypes: Case[];
}

export interface InstitutionTypes {
  authority: string;
  createdAt: string;
  id: number;
  institutionName: string;
  legalForm: string;
  organizationCode: string;
  userFirstName: string;
  userLastName: string;
}

interface TypesState {
  procedureTypes: ProcedureTypes[];
  procedureMethod: Case[];
  usageTypes: Case[];
  appraisalTypes: [];
  subdivisionTypes: Case[];
  authorizedInstitutions: Case[];
  administratingInstitutions: Case[];
  relatedUsers: ActiveUser[];
}

const initialState: TypesState = {
  procedureTypes: [],
  procedureMethod: [],
  usageTypes: [],
  appraisalTypes: [],
  subdivisionTypes: [],
  authorizedInstitutions: [],
  administratingInstitutions: [],
  relatedUsers: [],
};

export const typesSlice = createSlice({
  name: 'types',
  initialState,
  reducers: {},
  extraReducers: {
    [setProcedureTypes.toString()]: (state, { payload }) => {
      state.procedureTypes = payload;
    },
    [setProcedureMethod.toString()]: (state, { payload }) => {
      state.procedureMethod = payload;
    },
    [setUsageTypes.toString()]: (state, { payload }) => {
      state.usageTypes = payload;
    },
    [setAppraisalTypes.toString()]: (state, { payload }) => {
      state.appraisalTypes = payload;
    },
    [setSubdivisionTypes.toString()]: (state, { payload }) => {
      state.subdivisionTypes = payload;
    },
    [setAuthorizedInstitutions.toString()]: (state, { payload }) => {
      state.authorizedInstitutions = payload.map((element: InstitutionTypes) => ({
        description: element.institutionName,
        name: element.institutionName,
      }));
    },
    [setAdministratingInstitutions.toString()]: (state, { payload }) => {
      state.administratingInstitutions = payload.map((element: InstitutionTypes) => ({
        description: element.institutionName,
        name: element.institutionName,
      }));
    },
    [setRelatedUsers.toString()]: (state, { payload }) => {
      state.relatedUsers = payload;
    },
  },
});

export default typesSlice.reducer;
