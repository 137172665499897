import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, CssBaseline, createGenerateClassName } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './muiTheme';
import './i18n/i18n-config';
import './index.css';

const generateClassName = createGenerateClassName({
  seed: 'katri',
});

ReactDOM.render(
  <StylesProvider injectFirst generateClassName={generateClassName}>
    <CssBaseline />
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </StylesProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
