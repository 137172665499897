import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { BASE_URL } from 'utils/baseRequest';

export default i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(HttpApi)
  .init({
    backend: {
      loadPath: `${BASE_URL}api/classifiers`,
      parse: (data) => {
        const translation = {};
        const items = JSON.parse(data).message;
        // @ts-ignore
        items.forEach(({ key, valueET }) => (translation[key] = valueET));
        return translation;
      },
    },
    react: {
      useSuspense: false,
    },
    lng: 'et', // if you're using a language detector, do not define the lng option
    fallbackLng: 'et',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
