import { configureStore, combineReducers, Reducer, AnyAction } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userPermissionsReducer from './slices/userPermissionsSlice';
import institutionPermissionsReducer from './slices/institutionPermissionsSlice';
import templatesReducer from './slices/templatesSlice';
import authenticationReducer from './slices/authenticationSlice';
import reportsReducer from './slices/reportsSlice';
import typesReducer from './slices/typesSlice';
import observationsReducer from './slices/observationsSlice';
import procedureReducer from './slices/proceduresSlice';

const persistConfig = {
  key: 'root',
  storage,
};
const appReducer = combineReducers({
  userPermissions: userPermissionsReducer,
  institutionPermissions: institutionPermissionsReducer,
  templates: templatesReducer,
  authentication: authenticationReducer,
  reports: reportsReducer,
  types: typesReducer,
  observations: observationsReducer,
  procedures: procedureReducer
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === '@clearReduxState') {
    // clear redux state upon logout
    storage.removeItem('persist:root');
    state = {} as RootState;
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
