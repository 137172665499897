import { User, NewUserData, Permission } from 'utils/userData/types';
import { baseRequest } from 'utils/baseRequest';

const getUserData = async (endpoint: string): Promise<[]> => {
  try {
    const { data } = await baseRequest({
      method: 'get',
      endpoint: `api/${endpoint}`,
    });
    if (data.message && data.status === 'OK') {
      return data.message;
    }
    return [];
  } catch (error) {
    console.error(error);
  }
  return [];
};

const getCanSendForConfirmation = async (endpoint: string): Promise<boolean> => {
  try {
    const { data } = await baseRequest({
      method: 'get',
      endpoint: `api/${endpoint}`,
    });
    if (data.status === 'OK') {
      return data.message;
    }
  } catch (error) {
    console.error(error);
  }
  return false;
};

const postUserData = async (
  endpoint: string,
  content?: User | Permission<string | null> | NewUserData
): Promise<User | undefined | string> => {
  try {
    const { data } = await baseRequest({
      method: 'post',
      endpoint: `api/${endpoint}`,
      data: { ...content },
    });
    if (data.message) {
      return data.message;
    }
    return undefined;
  } catch (error) {
    console.error(error);
  }
  return undefined;
};

export { getUserData, postUserData, getCanSendForConfirmation };
