import { createSlice } from '@reduxjs/toolkit';
import { setUserData } from 'redux/actions/authenticationActions';
import { AuthenticationState, ActiveUser } from 'utils/authentication/types';

const initialState: AuthenticationState = {
  activeUser: <ActiveUser>{},
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {},
  extraReducers: {
    [setUserData.toString()]: (state, { payload }) => {
      const { firstName, lastName, identificationCode, roles, userPreference } = payload;
      state.activeUser = {
        firstName,
        lastName,
        identificationCode,
        roles,
        userPreference
      };
    },
  },
});
export default authenticationSlice.reducer;
